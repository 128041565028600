import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { VIDEO_TYPES } from 'consts';
import { withUrlContext } from 'hoc/url';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { withGetUser } from 'graphql/user';
import { generateModalId, get } from 'utils';
import { withData } from './data';

import { EventHighlightsUI } from './ui';

export class EventHighlights extends PureComponent {
    static displayName = 'EventHighlightsContainer';

    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        highlightsCount: PropTypes.number,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        loading: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        manageShareSettings: PropTypes.func.isRequired,
        matches: PropTypes.arrayOf(PropTypes.any),
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        pageId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        setFilter: PropTypes.func.isRequired,
        setSort: PropTypes.func.isRequired,
        setVisibility: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        sortKey: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        tabs: PropTypes.arrayOf(PropTypes.any),
        userId: PropTypes.string
    };

    static defaultProps = {
        highlightsCount: undefined,
        loading: false,
        matches: [],
        styles: undefined,
        pageId: undefined,
        tabs: [],
        userId: undefined
    };

    constructor(props) {
        super(props);

        this.manageShareSettings = this.manageShareSettings.bind(this);
        this.onNodeSelect = this.onNodeSelect.bind(this);
        this.openExport = this.openExport.bind(this);
        this.openVideoModal = this.openVideoModal.bind(this);

        this.selectedRef = createRef();
        this.videoPlayer = createRef();

        this.state = {
            ignoreSelection: false
        };
    }

    componentDidUpdate(prevProps) {
        const { pageId: prevPageId } = prevProps;
        const { pageId } = this.props;
        const { ignoreSelection } = this.state;

        if (pageId && pageId !== prevPageId) {
            if (this.selectedRef.current) {
                if (ignoreSelection) {
                    this.setState({
                        ignoreSelection: false
                    });
                } else {
                    this.selectedRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }

    manageShareSettings({ value }) {
        const { manageShareSettings } = this.props;
        const shareOn = value[0] === 'share';
        manageShareSettings(shareOn);
    }

    onNodeSelect({ id, withScroll, seekToAudio }) {
        const { onNodeSelect } = this.props;

        this.setState(
            {
                ignoreSelection: true
            },
            () => {
                onNodeSelect({ id, withScroll, seekToAudio });
            }
        );
    }

    openExport() {
        const { history, tabs, location, sortKey, filterKey } = this.props;
        history.push(
            generateModalId({ type: 'exportHighlights', tabId: tabs[0], location, id: `${sortKey}|${filterKey}` })
        );
    }

    openVideoModal() {
        const { history, pathname, tabs } = this.props;
        // Clicking the thumbnail will remove the preview overlay and open the modal that plays the video
        // Because the preview overlay is removed, we need to put it back so the user can re-open the modal
        if (this.videoPlayer && this.videoPlayer.current) this.videoPlayer.current.showPreview();
        history.push(
            generateModalId({ pathname, id: VIDEO_TYPES.workspaceHighlights, tabId: tabs[0], type: 'videoPlayer' })
        );
    }

    render() {
        const {
            filterKey,
            highlightsCount,
            loading,
            matches,
            mediaPlayer,
            pageId,
            setFilter,
            setSort,
            setVisibility,
            shareBookmarks,
            sortKey,
            styles,
            userId
        } = this.props;
        return (
            <EventHighlightsUI
                filterKey={filterKey}
                hasHighlights={highlightsCount && highlightsCount > 0}
                loading={loading}
                manageShareSettings={this.manageShareSettings}
                matches={matches}
                mediaPlayer={mediaPlayer}
                onNodeSelect={this.onNodeSelect}
                openExport={this.openExport}
                openVideoModal={this.openVideoModal}
                pageId={pageId}
                selectedRef={this.selectedRef}
                setFilter={setFilter}
                setSort={setSort}
                setVisibility={setVisibility}
                shareBookmarks={shareBookmarks}
                sortKey={sortKey}
                styles={styles}
                userId={userId}
                videoPlayerRef={this.videoPlayer}
            />
        );
    }
}

export const EventHighlightsContainer = compose(
    withUrlContext(['tabs', 'location', 'pathname']),
    withEventMediaPlayer({ allowLiveStream: true, fetchPolicy: 'cache-only' }),
    withData(),
    withGetUser({ fetchPolicy: 'cache-first' }),
    withPropsOnChange(['user'], ({ user }) => ({
        userId: get(user, 'id')
    }))
)(EventHighlights);
